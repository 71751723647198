

export const getTheme = (theme) => {
  return {
    "--theme-primary": theme,}
};

export function applyTheme(themeName) {
  let theme = getTheme(themeName);
 
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}
