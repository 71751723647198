import React from "react";
import { MdShowChart, MdDashboard } from "react-icons/md";
import { FaBookReader, FaCog } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { GiSkills } from "react-icons/gi";
import { RiTeamFill, RiUserSettingsFill } from "react-icons/ri";

const navigation = [
  { name: " المهارات", href: "/skills", icon: GiSkills, current: true },
  { name: " الفريق", href: "/team", icon: RiTeamFill, current: true },

  {
    name: " الإحصائيات",
    href: "/dashboard",
    icon: MdShowChart,
    current: false,
  },
  {
    name: "الاشتراكات",
    href: "/users",
    icon: RiUserSettingsFill,
    current: false,
  },

  { name: "الاعدادات", href: "/Settings", icon: FaCog, current: false },
];

export default navigation;
