import {
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
} from "../../constants/ActionTypes";
import {
  THEME_COLOR,
  BUSINESS_LOGO,
  COLLAPSE_NAV,
} from "../../constants/ThemeSetting";

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, navCollapsed };
}
export function collapseNav(navCollapsed) {
  return { type: COLLAPSE_NAV, navCollapsed };
}

export function updateWindowWidth(width) {
  return (dispatch) => {
    dispatch({ type: WINDOW_WIDTH, width });
  };
}
export function updateLogo(logo) {
  return (dispatch) => {
    dispatch({ type: BUSINESS_LOGO, logo });
  };
}

export function updateThemeColor(themeColor) {
  return (dispatch) => {
    dispatch({ type: THEME_COLOR, themeColor });
  };
}
