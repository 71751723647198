import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import { applyTheme } from "../../util/theme/applyTheme";
import { setInitUrl, userSignIn, setIfDemo } from "../../appRedux/actions/Auth";

const RestrictedRoute = ({
  component: Component,
  location,
  isAuthorized,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthorized ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const AppContent = () => {
  const dispatch = useDispatch();
  const initURL = useSelector(({ auth }) => auth.initURL);
  const isAuthorized = useSelector(
    ({ auth }) =>
      auth.token != null && auth.token != undefined && auth.token != ""
  );

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    link.className = "gx-style";
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname + location.search));
    }
  }, [dispatch, initURL, location.pathname, location.search]);

  useEffect(() => {
    if (isAuthorized) {
      applyTheme(localStorage.getItem("theme"));
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/demo") {
      if (!isAuthorized) {
        if (location.pathname === "/demo") {
          dispatch(setIfDemo(true));
          dispatch(
            userSignIn({ email: "demo@wajeez.co", password: "demo1234" })
          );
        } else history.push("/login");
      } else if (
        initURL === "" ||
        initURL === "/" ||
        initURL === "/login" ||
        initURL === "/demo"
      ) {
        history.push("/dashboard");
      } else {
        history.push(initURL);
      }
    }
  }, [isAuthorized, initURL, location, history]);

  return (
    <Switch>
      <Route exact path="/login" component={SignIn} />
      <RestrictedRoute
        path={`${match.url}`}
        isAuthorized={isAuthorized}
        location={location}
        component={MainApp}
      />
    </Switch>
  );
};

export default memo(AppContent);
