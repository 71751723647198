import AuthTypes from "../type/authTypes";
import getTimezoneOffset from "../../util/getTimeZoneOffset";
import { decodeToken } from "../../util/decodeToken";


const decodedToken = localStorage.getItem("token")
  ? decodeToken(localStorage.getItem("token") || "")
  : null;

let user = JSON.parse(localStorage.getItem("user") || "{}");

const INITIAL_STATE = {
  user: user || null,
  authenticated: false,
  isDemo:localStorage.getItem('isDemo') ||false,
  token: localStorage.getItem("token") || null,
  initURL: "",
  businessData: (JSON.parse(localStorage.getItem('business_data')))|| {name:''},
  permission: decodedToken ? decodedToken.Permission : null,
  role: decodedToken ? decodedToken.Role : null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    // 'accept': 'text/plain',
    Authorization: "Bearer " + (localStorage.getItem("token") || ""),
  },
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.RESET_AUTH:
      return INITIAL_STATE;
    case AuthTypes.SET_USER:
      return {
        ...state,
        user: action.payload.user,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        permission: decodeToken(action.payload.token).Permission,
        role: decodeToken(action.payload.token).Role,
      };
    case AuthTypes.INIT_URL:
      return {
        ...state,
        initURL: action.payload,
      };

    case AuthTypes.SET_HEADERS:
      return {
        ...state,
        headers: action.payload,
      };
    case AuthTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case AuthTypes.SET_REFRESH_TOKEN:
      console.log(action)
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        user: action.payload.user,
        permission: decodeToken(action.payload.token).Permission,
      };
    default:
      return state;
  }
};

export default AuthReducer;
