import store from "../../appRedux/store";
import { identityApi as api } from "../Api";


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class BusinessGroupServices {
  getMyBusinessGroup() {
    return api.get(`business/BusinessGroup`,{
      headers: {...store.getState().auth.headers, "x-app-language-id": 1 }}).then((response) => {
      return response;
    });
  }
  
  updateMyBusinessGroup(body) {
    return api.put(`business/BusinessGroup`,body, {
      headers: { ...store.getState().auth.headers, "x-app-language-id": 1 },
    }).then((response) => {
      return response.data.data;
    });
  }
 
}

export default new BusinessGroupServices();
