import {
  IS_DEMO,
  FETCH_START,
  FETCH_SINGLE_ERROR,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  BUSINESS_DATA,
  HEADER,
  USER_ID,
  GROUP_ID,
  REFRESH_TOKEN,
} from "../../constants/ActionTypes";
// import api from "../../util/Api";
import Api, { identityApi, mediaApi, contentApi } from "../../services/Api";
import { updateLogo, updateThemeColor } from "./Setting";
import BusinessGroupServices from "../../services/identityServices/BusinessGroupServices";
import getTimezoneOffset from "../../util/getTimeZoneOffset";
import { history } from "../store";
import AuthService from "../../services/identityServices/authServices/authActions.service";
import { decodeToken } from "../../util/decodeToken";
import { applyTheme } from "../../util/theme/applyTheme";
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const setIfDemo = (bool) => {
  localStorage.setItem("isDemo", bool);
  return {
    type: IS_DEMO,
    payload: bool,
  };
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    return AuthService.login(email, password)
      .then(async ({ data }) => {
        const headers = {
          "Accept-Language": "ar",
          timezone: getTimezoneOffset(),
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.data.token,
        };

        localStorage.setItem("token", data.data.token);
        localStorage.setItem("refreshToken", data.data.refreshToken);
        localStorage.setItem("user", JSON.stringify({ ...data.data }));
        localStorage.setItem("Authorization", "Bearer " + data.data.token);
        dispatch({ type: FETCH_SUCCESS });
        // Api.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${data.data.token}`;
        // identityApi.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${data.data.token}`;
        // mediaApi.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${data.data.token}`;
        // mediaApi.defaults.headers.common[
        //   "Authorization"
        // ] = `Bearer ${data.data.token}`;

        dispatch({
          type: REFRESH_TOKEN,
          payload: data.data,
        });
        dispatch({ type: USER_DATA, payload: { ...data.data } });
        dispatch({ type: USER_ID, payload: data.data.id });
        //
        dispatch({ type: HEADER, payload: headers });
        dispatch({ type: USER_TOKEN_SET, payload: data.data.token });
        if (window.location.pathname === "/demo") {
          history.push("/dashboard");
          dispatch(setInitUrl("/dashboard"));
        }
        return headers;
      })
      .then((data) => {
        data && dispatch(getBusinessData(data));
      })
      .catch((error) => {
        if (error.name !== "CanceledError") {
          dispatch({
            type: FETCH_SINGLE_ERROR,
            payload: error.response.data.errors[0].localized_msg,
          });
        }
      });
  };
};

export const getBusinessData = (headers) => {
  return async (dispatch) => {
    await BusinessGroupServices.getMyBusinessGroup(headers)
      .then(async (res) => {
        let color = res.data.data.themeColor
          ? !res.data.data.themeColor.includes("-")
            ? res.data.data.themeColor
            : res.data.data.themeColor?.split("-")[2]
          : null;

        localStorage.setItem("group_id", res.data.data.id);

        localStorage.setItem("business_data", JSON.stringify(res.data.data));
        localStorage.setItem("logo", JSON.stringify(res.data.data.logo));
        dispatch({ type: GROUP_ID, payload: res.data.data.id });
        dispatch({ type: BUSINESS_DATA, payload: res.data.data });
        dispatch(updateLogo(res.data.data.logo));

        applyTheme(color);
        color && dispatch(updateThemeColor("primary"));

        (await color) && localStorage.setItem("theme", color);
      })
      .catch((err) => {
        getBusinessData(headers);
      });
  };
};

export const refreshToken = (data) => {
  return async (dispatch) => {
    dispatch({
      type: REFRESH_TOKEN,
      payload: data,
    });
  };
};
export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    localStorage.removeItem("token");
    localStorage.clear();
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    // window.location.reload();
  };
};
