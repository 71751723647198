import { unAuthenticatedIdentityApi as api } from "../../Api";
import TokenService from "./token.service";

class AuthService {
  login(email, password) {
    return api
      .post("Account/authenticate", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.data) {
          const data = response.data.data;
          TokenService.setUser({
            token: data.token,
            refreshToken: data.refreshToken,
            user: { id: data.id, name: data.name, email: data.email },
          });
        }

        return response;
      });
  }

  logout() {
    TokenService.removeUser();
  }
}

export default new AuthService();
