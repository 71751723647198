import axios from 'axios'
import {REACT_APP_BASE_URL} from '../constants/ActionTypes'
// store
import store from '../appRedux/store';



function select(state) {
  return state.auth.headers
}
let headers = select(store.getState())

// configuration
const api = axios.create({
  baseURL: REACT_APP_BASE_URL,
})

export default api