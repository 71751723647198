import {
  identityApi as api,
  unAuthenticatedIdentityApi as baseApi,
} from "../Api";
import setupInterceptors from "../setupInterceptors";

const identitySetupInterceptors = () => {
  setupInterceptors(baseApi, true);
  return setupInterceptors(api);
};

export default identitySetupInterceptors;
