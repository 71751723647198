import React from 'react';


export default function getTimezoneOffset(){
    let offset = new Date().toTimeString().split(' ');
    let time='';
    offset[1].replace(/(\w)(\D)/g,'').split('').forEach((item,i)=>{
        time = time +(i===2?':'+item:item)
    })
    offset =offset[1].replace(/(\w)/g,'')+time

    return offset
}