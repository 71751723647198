import { identityApi } from "../../Api";
import AuthActions from "../../../appRedux/actions/authActions";
import store from "../../../appRedux/store";
import checkTokenExpiry from "../../tokenHelper/checkTokenExpiry";
import { refreshToken } from "../../../appRedux/actions/Auth";
class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem("refreshToken");
    const refreshToken = store.getState().auth.refreshToken;
    return refreshToken;
  }

  getLocalAccessToken() {
    return localStorage.getItem("token");
    const token = store.getState().auth.token;
    return token;
  }

  updateLocalAccessToken(token) {
    localStorage.setItem("token", token);
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user") || "{}");
  }

  setUser(data) {
    localStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("token", data.token);
    localStorage.setItem("refreshToken", data.refreshToken);
  }

  checkTokenExpiry() {
    const token = this.getLocalAccessToken();
    if (checkTokenExpiry(this.getLocalAccessToken())) {
      return true;
    }
    return false;
  }
  refresh() {
    return identityApi
      .post("Account/token/refresh", {
        token: this.getLocalAccessToken(),
        refreshToken: this.getLocalRefreshToken(),
      })
      .then((response) => {
        const data = response.data.data;
        const user = {
          user: { id: data.id, name: data.name, email: data.email },
          refreshToken: data.refreshToken,
          token: data.token,
        };
        store.dispatch(refreshToken(user));
        this.updateLocalAccessToken(data.token);
        this.setUser(user);
      })

  }

  removeUser() {
    localStorage.clear();
  }
}

export default new TokenService();
