import TokenService from "../identityServices/authServices/token.service";

import { unAuthenticatedIdentityApi as identityApi } from "../Api";
import checkTokenExpiry from "./checkTokenExpiry";
import mutex from "./Mutex";
import tokenService from "../identityServices/authServices/token.service";
import store from "../../appRedux/store";
import { refreshToken, userSignOut } from "../../appRedux/actions/Auth";
// import { Mutex } from "async-mutex";

// const tokenMutex = new Mutex();

export default async function getAccessTokenForUsageService() {
  return await mutex.dispatch(async () => {
    const token = tokenService.getLocalAccessToken();
    if (!token) return null;
    if (checkTokenExpiry(token)) {
      return token;
    } else {
      return await refreshTokenService();
    }
  });

  // return await tokenMutex.dispatch(async () => {
  //   if (tokenService.checkTokenExpiry()) {
  //     return TokenService.getLocalAccessToken();
  //   } else {
  //     return await refreshTokenService();
  //   }
  // });
}

const refreshTokenService = async () => {
  try {
    const rs = await identityApi.post("account/token/refresh", {
      token: TokenService.getLocalAccessToken(),
      refreshToken: TokenService.getLocalRefreshToken(),
    });

    const data = rs.data.data;
    const user = {
      user: { id: data.id, name: data.name, email: data.email },
      refreshToken: data.refreshToken,
      token: data.token,
    };
    store.dispatch(refreshToken(user));
    TokenService.updateLocalAccessToken(data.token);
    TokenService.setUser(user);
    return user.token;
  } catch (_error) {
    console.log(_error);
    TokenService.removeUser();
    store.dispatch(userSignOut());
    return Promise.reject(_error);
  }
};
