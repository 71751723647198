import React, { useState, useEffect } from "react";
import navigation from "./sidebarContent";
import { useSelector } from "react-redux";
import { RiMenuUnfoldLine, RiMenuFoldLine } from "react-icons/ri";
import { Link, useHistory, useLocation } from "react-router-dom";
import SidebarFooter from "./sidebarFooter";
import SidebarHeader from "./sidebarHeader";
import { motion, useAnimation } from "framer-motion";
import Tooltip from "../../components/dataDisplay/toolTip";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = (props) => {
  const history = useHistory();
  const { themeColor } = useSelector(({ settings }) => settings);
  const [navContent, setNavContant] = useState(navigation);
  const controls = useAnimation();
  const [active, setActive] = useState(false);

  const controlText = useAnimation();
  const controlIconText = useAnimation();
  const controlLogo = useAnimation();
  const location = useLocation();
  const newSelect = (prop) => () => {
    const content = navContent.map((item) => {
      if (item.current === true) {
        return { ...item, current: false };
      } else return item;
    });
    content[prop] = { ...content[prop], current: true };
    setNavContant(content);
    history.push(content[prop].href);
  };

  const showMore = () => {
    controls.start({
      width: "210px",
      transition: { duration: 0.001 },
    });
    controlText.start({
      opacity: 1,
      display: "block",
      transition: { delay: 0.3 },
      flexBasis: "139px",
      marginRight: "8px",
      // flexGrow: 1,
    });
    controlIconText.start({});
    controlLogo.start({
      margin: "0",
      marginRight: "16px",
      flexBasis: "55px",
      border: "1px solid #dadeea",
      borderRadius: "8px",
    });
    setActive(true);
  };

  const showLess = () => {
    controls.start({
      width: "75px",
      transition: { duration: 0.001 },
    });

    controlText.start({
      opacity: 0,
      display: "none",
    });

    controlIconText.start({
      textAlign: "center",
    });
    controlLogo.start({
      textAlign: "center",
      marginRight: "auto",
      margin: "auto",
      flexBasis: "55px",
      border: "1px solid #dadeea",
      borderRadius: "8px",
    });

    setActive(false);
  };

  useEffect(() => {
    showMore();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1200) {
      showMore();
    } else {
      showLess();
    }

    const resizeListener = (event) => {
      if (event.target.innerWidth > 1200) {
        showMore();
      } else {
        showLess();
      }
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <motion.div
        animate={controls}
        className="flex flex-col items-center h-full overflow-hidden animate duration-300 text-gray-900 dark bg-white print-hidden max-w-60 min-h-screen"
      >
        <div
          className={`h-20 px-4 bg-gray-250 w-full  flex ${
            active ? "justify-end" : "justify-center"
          } items-center`}
        >
          {active && (
            <RiMenuUnfoldLine
              onClick={showLess}
              className="w-7 h-6 text-primary cursor-pointer"
            />
          )}
          {!active && (
            <RiMenuFoldLine
              onClick={showMore}
              className="w-7 h-6 text-primary cursor-pointer"
            />
          )}
        </div>
        <SidebarHeader
          controlLogo={controlLogo}
          controlText={controlText}
          props={props}
        />

        <div className="w-full ps-2 justify-between h-full flex flex-col ">
          <div className="flex flex-col items-center w-full mt-1 border-gray-700">
            <nav className="ps-2  space-y-2 w-full  ">
              {navContent.map((item, i) => (
                <Link
                  key={i}
                  to={{
                    pathname: item.href,
                    state: { from: location },
                  }}
                >
                  <a
                    onClick={newSelect(i)}
                    key={item.name}
                    className={classNames(
                      location.pathname.indexOf(item.href) === 0
                        ? ` border-e-4 rounded-sm border-primary text-primary`
                        : `text-[#a7b1c2]  hover:text-primary`,
                      ` group  flex items-center  ps-2 py-1 mt-3 text-[15px] font-bold `
                    )}
                  >
                    <motion.p animate={controlIconText}>
                      {active ? (
                        <item.icon
                          className={classNames(
                            location.pathname.indexOf(item.href) === 0
                              ? `text-${props.theme} `
                              : `text-[#a7b1c2] group-hover:text-${props.theme}`,
                            "ml-4 h-5 w-5"
                          )}
                          aria-hidden="true"
                        />
                      ) : (
                        <Tooltip content={item.name}>
                          <item.icon
                            className={classNames(
                              location.pathname.indexOf(item.href) === 0
                                ? `text-${props.theme} `
                                : `text-[#a7b1c2] group-hover:text-${props.theme}`,
                              "ml-4 h-5 w-5"
                            )}
                            aria-hidden="true"
                          />
                        </Tooltip>
                      )}
                    </motion.p>
                    <motion.p animate={controlText}>{item.name}</motion.p>
                  </a>
                </Link>
              ))}
            </nav>
          </div>
          {/* <div className="w-full ps-4 leading-9 mb-4">
          <div className="ps-2 self-start">
            <span className={` font-bold  text-normal text-${props.theme}`}>
              تطبيق وجيز
            </span>
          </div>
          <div className=" ps-2 self-start text-xs font-bold leading-5">
            <span> مع تطبيق وجيز صار التعلُّم ممتعاً دون جهد أو وقت طويل. استمع الى ملخصات الكتب في طريقك الى المنزل أو أثناء التمرين </span>
          </div>
        </div> */}
        </div>
        <a className="flex items-center  w-full h-16 mt-auto  ">
          <SidebarFooter
            controlText={controlText}
            controlIconText={controlIconText}
            isSidebarActive={active}
          />
        </a>
      </motion.div>
    </>
  );
};

export default Sidebar;
