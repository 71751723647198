import React from "react";
import { createPopper } from "@popperjs/core";

const Tooltip = ({ children, content }) => {
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const btnRef = React.createRef();
  const tooltipRef = React.createRef();
  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: "top",
    });
    setTooltipShow(true);
  };
  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full ">
          <div
            onMouseEnter={openLeftTooltip}
            onMouseLeave={closeLeftTooltip}
            ref={btnRef}
          >
            {children}
          </div>
          <div
            className={
              (tooltipShow ? "" : "hidden ") +
              "bg-" +
              "gray" +
              "-600 border-0 mb-3 block z-50 font-normal leading-normal text-sm max-w-xs  no-underline rounded-lg"
            }
            ref={tooltipRef}
          >
            <div>
              <div
                className={
                  "bg-" +
                  "gray" +
                  "-600 text-white opacity-75 font-semibold  border-b  uppercase rounded-t-lg"
                }
              ></div>
              <div className="text-white p-3">{content}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tooltip;
