import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  GROUP_ID,
  HEADER,
  USER_ID,
  BUSINESS_DATA,
  IS_DEMO,
  REFRESH_TOKEN,
  USER_PERMITIONS,
} from "../../constants/ActionTypes";
import getTimezoneOffset from "../../util/getTimeZoneOffset";
import { decodeToken } from "../../util/decodeToken";

const decodedToken = (token) =>
  decodeToken(token || localStorage.getItem("token") || "");
const INIT_STATE = {
  token: localStorage.getItem("token"),
  initURL: "",
  refreshToken: localStorage.getItem("refreshToken") || null,
  isDemo: localStorage.getItem("isDemo") || false,
  businessData: JSON.parse(localStorage.getItem("business_data")) || {
    name: "",
  },
  authUser: JSON.parse(localStorage.getItem("user")),
  user_id: localStorage.getItem("user_id"),
  group_id: localStorage.getItem("group_id"),
  headers: {
    "Accept-Language": "ar",
    "Access-Control-Allow-Origin": "*",
    //"Content-Type": "application/json",
    // 'Origin': '*',
    "Access-Control-Allow-Headers": "*",
    timezone: getTimezoneOffset(),
    // 'accept': 'text/plain',
    Authorization: localStorage.getItem("Authorization"),
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case IS_DEMO: {
      return { ...state, isDemo: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: "",
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: { ...action.payload },
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
        headers: {
          ...state.headers,
          Authorization: `Bearer ${action.payload}`,
        },
      };
    }

    case HEADER: {
      return {
        ...state,
        headers: action.payload,
      };
    }

    case USER_ID: {
      return {
        ...state,
        user_id: action.payload,
      };
    }

    case GROUP_ID: {
      let business_role = decodedToken(state.token).Permission.includes(
        `89.${action.payload}`
      )
        ? "admin"
        : decodedToken(state.token).Permission.includes(`90.${action.payload}`)
        ? "moderator"
        : "user";

      localStorage.setItem(
        "user",
        JSON.stringify({ ...state.authUser, business_role })
      );

      return {
        ...state,
        group_id: action.payload,
        authUser: { ...state.authUser, business_role },
      };
    }

    case BUSINESS_DATA: {
      return {
        ...state,
        businessData: action.payload,
      };
    }
    case REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        user: action.payload.user,
        permission: decodeToken(action.payload.token).Permission,
      };

    default:
      return state;
  }
};
