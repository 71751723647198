import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

import { arabicStringChecker } from "../../util/arabicStringChecker";
import MediaServices from "../../services/mediaservices/MediaServices";

const SidebarHeader = (props) => {
  const { businessData } = useSelector(({ auth }) => auth);
  const [logo, setLogo] = useState("");
  useEffect(() => {
    MediaServices.getMediabyId(businessData.logoReference).then((res) => {
      setLogo(res);
    });
  }, [businessData]);
  return (
    <div className="w-full py-2 mt-4">
      <div className="flex justify-center items-center">
        <motion.p animate={props.controlLogo}>
          {logo ? (
            <img
              alt="logo"
              src={logo}
              className="w-13 h-13 rounded-lg object-contain"
            />
          ) : (
            <svg
              className="w-13 h-13 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
            </svg>
          )}
        </motion.p>
        <motion.p animate={props.controlText}>
          {" "}
          <div
            className={`text-[15px] ${
              arabicStringChecker(props.props.businessData.name)
                ? "text-right"
                : "text-left"
            } pe-4 font-bold text-gray-900 align-middle`}
            style={{ wordBreak: "break-word" }}
          >
            {props.props.businessData.name}
          </div>{" "}
        </motion.p>
      </div>
    </div>
  );
};

export default SidebarHeader;
