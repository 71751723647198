import {combineReducers} from "redux";
import Auth from "./Auth";
import Common from "./Common";
import Settings from './Settings'
import AuthReducer from "./authReducer";
import {connectRouter} from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  common: Common,
  settings:Settings
 
});

export default createRootReducer;
