import TokenService from "./identityServices/authServices/token.service";
import {
  userSignOut,
  userSignIn,
  refreshToken,
} from "../appRedux/actions/Auth";
import store from "../appRedux/store";
import ErrorActions from "../appRedux/actions/errorActions";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../appRedux/actions/Common";
import Api, { identityApi } from "./Api";
import getAccessTokenForUsageService from "./tokenHelper/getAccessTokenForUsageService";

const setupInterceptors = (api = Api, withoutToken = false) => {
  if (!withoutToken)
    api.interceptors.request.use(
      async (config) => {
        // const token = TokenService.getLocalAccessToken() || null;
        const token = (await getAccessTokenForUsageService()) || null;

        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: token ? "Bearer " + token : "",
          },
        };
      },
      (error) => {
        return Promise.reject(error);
      }
    );

  api.interceptors.response.use(
    async (res) => {
      store.dispatch(fetchSuccess());

      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig?.url !== "login" && err.response) {
        // Access Token was expired
        if (err.response.status >= 500 || err.response.status === 403) {
          store.dispatch(
            fetchError({
              type: "page",
              msg: err.response.data.message,
            })
          );
        } else if (err.response.status === 401 && !originalConfig?._retry) {
          // originalConfig._retry = true;
          // try {
          //   const rs = await identityApi.post("account/token/refresh", {
          //     token: TokenService.getLocalAccessToken(),
          //     refreshToken: TokenService.getLocalRefreshToken(),
          //   });
          //   const data = rs.data.data;
          //   const user = {
          //     user: { id: data.id, name: data.name, email: data.email },
          //     refreshToken: data.refreshToken,
          //     token: data.token,
          //   };
          //   store.dispatch(refreshToken(user));
          //   TokenService.updateLocalAccessToken(data.token);
          //   TokenService.setUser(user);
          //   return api(originalConfig);
          // } catch (_error) {
          //   TokenService.removeUser();
          //   store.dispatch(userSignOut());
          //   return Promise.reject(_error);
          // }
        } else {
          store.dispatch(
            fetchError({
              type: "page",
              msg: err.response.data.message,
            })
          );
        }
      } else if (err.name === "CanceledError") {
        return Promise.reject(err);
      }

      return Promise.reject(err);
    }
  );
};

export default setupInterceptors;
