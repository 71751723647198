import React, { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import store from "../appRedux/store";
import { hideMessage } from "../appRedux/actions/Common";
import { userSignIn, userSignOut } from "../appRedux/actions/Auth";
import logo from "../assets/images/logo.png";
import { XCircleIcon } from "@heroicons/react/solid";
import { text } from "react-placeholder/lib/placeholders";
import { Alert } from "antd";
import Swal from "sweetalert2";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import wajeez_login_wallpaper from "../assets/images/wajeez_login_wallpaper.jpg";

const SignIn = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const isAuthorized = useSelector(
    ({ auth }) =>
      auth.token !== null && auth.token !== undefined && auth.token !== ""
  );
  const authUser = useSelector(({ auth }) => auth.authUser);
  // const [values, setValues] = useState({ email: "", password: "" });
  const { error, loading } = useSelector(({ common }) => common);
  // const handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };
  useEffect(() => {
    if (isAuthorized && authUser?.business_role) {
      if (
        authUser.business_role === "admin" ||
        authUser.business_role === "moderator"
      ) {
        props.history.push("/");
      } else {
        dispatch(userSignOut());

        Swal.fire({
          title: "عذرا, لا تملك صلاحية للدخول",
          html: `<div className=''>
                </div>`,
          icon: "error",
          confirmButtonText: "حسناً",
        });
      }
    }
  }, [isAuthorized, authUser, props.history]);

  const onSubmit = (data) => {
    dispatch(userSignIn(data));
  };

  const errorAlert = () => {
    return error?.msg && error?.type === "page" ? (
      <div className="w-4/5 absolute top-4 left-1/2 -translate-x-1/2 rounded-md">
        <Alert
          message={error?.msg}
          type="error"
          closable
          onClose={() => store.dispatch(hideMessage())}
          className="font-tajawal"
        />
      </div>
    ) : null;
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleSubmit(onSubmit);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div
      style={{ backgroundImage: `url(${wajeez_login_wallpaper})` }}
      dir="rtl"
      className="relative	 bg-center  bg-no-repeat bg-cover	 bg-gray-800  h-full min-h-screen"
    >
      <div className="bg-gray-800 h-full bg-opacity-70 bg-center  bg-no-repeat bg-cover ">
        <div
          className="hidden sm:block sm:absolute sm:inset-0"
          aria-hidden="true"
        >
          {/* <svg
          className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
        </svg> */}
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <Popover>
            {({ open }) => (
              <>
                <nav
                  className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                  aria-label="Global"
                >
                  <div className="flex items-center flex-1">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <a href="#">
                        <span className="sr-only">Workflow</span>
                        <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
                      </a>
                      <div className="-mr-2 flex items-center md:hidden">
                        <Popover.Button className="bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                </nav>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    static
                    className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                  >
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                          <img className="h-8 w-auto" src={logo} alt="" />
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <main className="mt-16 sm:mt-24">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div className="text-right">
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-3xl xl:text-4xl space-y-2">
                      <span className="md:block">
                        خطوة واحدة تفصلك عن تطوير ذاتك و زيادة معرفتك
                      </span>{" "}
                      <span className="md:block" style={{ color: "#3A7A85" }}>
                        دقائق معدودة يوميا ستجعلك قارئاً استثنائياً.{" "}
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      في وجيز ستجد معرفة ستغير حياتك وتدفعك نحو النجاح والسعادة،
                      وذلك باختصار وبأسلوب يلاءم نمط حياتك
                    </p>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                  <div className="relative bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                    <div className="p-4  sm:px-10">{errorAlert()}</div>

                    <div className="px-4 py-8 sm:px-10">
                      <div className="pb-6">
                        <p className="text-center text-3xl font-bold">
                          تسجيل الدخول
                        </p>
                      </div>

                      <div className="mt-6">
                        <div>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="relative h-[38px] mb-9">
                              <label
                                htmlFor="mobile-or-email"
                                className="sr-only"
                              >
                                البريد الإلكتروني للمستخدم
                              </label>
                              <input
                                type="text"
                                id="mobile-or-email"
                                autoComplete="email"
                                placeholder="البريد الإلكتروني للمستخدم	"
                                className="block w-full h-full py-2 px-3 shadow-sm outline-indigo-500 sm:text-sm border-[1px] border-gray-300 rounded-md"
                                {...register("email", { required: true })}
                              />
                              {errors.email && (
                                <div className="absolute top-[42px] right-0 text-sm text-error flex items-center pointer-events-none">
                                  <ExclamationCircleIcon
                                    className="h-5 w-5 me-1"
                                    aria-hidden="true"
                                  />{" "}
                                  هذا الحقل مطلوب
                                </div>
                              )}
                            </div>
                            <div className="relative h-[38px] mb-9">
                              <label htmlFor="password" className="sr-only">
                                كلمة المرور
                              </label>
                              <input
                                id="password"
                                type="password"
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmit(onSubmit);
                                  }
                                }}
                                placeholder=" كلمة المرور"
                                autoComplete="current-password"
                                className="block w-full h-full py-2 px-3 shadow-sm outline-indigo-500 sm:text-sm border-[1px] border-gray-300 rounded-md"
                                {...register("password", { required: true })}
                              />
                              {errors.password && (
                                <div className="absolute top-[42px] right-0 text-sm text-error flex items-center pointer-events-none">
                                  <ExclamationCircleIcon
                                    className="h-5 w-5 me-1"
                                    aria-hidden="true"
                                  />{" "}
                                  هذا الحقل مطلوب
                                </div>
                              )}
                            </div>
                            <div>
                              <button
                                type="submit"
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmit(onSubmit);
                                  }
                                }}
                                style={{ backgroundColor: "#3A7A85" }}
                                className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                تسجيل الدخول
                                {loading && (
                                  <AiOutlineLoading3Quarters
                                    className={`animate-spin h-4 w-4 ms-2 font-medium`}
                                  />
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                      {/* <p className="text-xs leading-5 text-gray-500">
                      By signing up, you agree to our{' '}
                      <a href="#" className="font-medium text-gray-900 hover:underline">
                        Terms
                      </a>
                      ,{' '}
                      <a href="#" className="font-medium text-gray-900 hover:underline">
                        Data Policy
                      </a>{' '}
                      and{' '}
                      <a href="#" className="font-medium text-gray-900 hover:underline">
                        Cookies Policy
                      </a>
                      .
                    </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
