import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { LogoutIcon, CogIcon, UserCircleIcon } from "@heroicons/react/outline";
import { userSignOut } from "../../appRedux/actions/Auth";
import Tooltip from "../../components/dataDisplay/toolTip";

const SidebarFooter = ({ controlText, controlIconText, isSidebarActive }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  return (
    <div className="flex-shrink-0 flex  ursor-pointer p-4">
      <div className="flex items-center">
        <div className="mr-3 pb-[40px]">
          <a
            onClick={() => dispatch(userSignOut())}
            className="text-sm font-medium flex cursor-pointer"
          >
            {" "}
            <motion.p animate={controlIconText}>
              {isSidebarActive ? (
                <LogoutIcon
                  className="ml-3 h-6 w-6 text-gray-500 group-hover:text-gray-700"
                  aria-hidden="true"
                />
              ) : (
                <Tooltip content={"تسجيل الخروج"}>
                  <LogoutIcon
                    className="ml-3 h-6 w-6 text-gray-500 group-hover:text-gray-700"
                    aria-hidden="true"
                  />
                </Tooltip>
              )}
            </motion.p>
            <motion.p animate={controlText}>تسجيل الخروج</motion.p>
          </a>
          {/* <p className="text-sm font-medium text-gray-500 ">مشاهدة الملف الشخصي</p> */}
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
